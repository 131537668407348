<h5 class="irt-no-margin">CONDIZIONI GENERALI</h5>

<div class="irt-contract-conditions-layout">
  <ol
    class="irt-contract-conditions"
    style="font-size: 8px">
    <li>
      La cancellazione della prenotazione deve essere comunicata via email
      all'Indirizzo info@irent-autonoleggio.com entro le 48 ore precedenti alla
      data di ritiro. Nessun rimborso verrà corrisposto nel caso in cui il
      cliente non si presenti al momento del ritiro del veicolo, se arriva in
      ritardo rispetto ai tempi stabiliti, se riconsegna il veicolo prima del
      previsto, se annulla la prenotazione dopo la data di ritiro o se rinuncia
      agli extra richiesti e pagati. Nessun rimborso verrà corrisposto se, al
      momento del ritiro del veicolo, il cliente non ha tutti i requisiti
      indicati nelle Condizioni di noleggio ed in particolare, in via
      esemplificativa: a) se non viene presentata una patente valida da almeno
      un anno; b) se non si hanno 18 anni (età minima per la guida) c) se si
      hanno più di 75 anni. Per patto espresso il deposito cauzionale, ove
      previsto, verrà trattenuto a garanzia del soddisfacimento di tutte le
      obbligazioni a carico del cliente di cui al contratto di noleggio, senza
      alcun obbligo di restituzione a carico del locatore, il quale potrà
      imputarlo ai crediti maturati in forza del contratto di noleggio.
      In alternativa al deposito cauzionale in contanti, a garanzia del soddisfacimento di tutte le obbligazioni a
      carico del cliente di cui al contratto di noleggio, il Cliente dovrà rilasciare i dati completi di una carta di
      credito con capienza non inferiore ad euro 2.100,00€, autorizzandosi il Locatore ad addebitare su tale
      carta tutte le somme dovute fino a concorrenza dei crediti maturati in forza del contratto di noleggio.
    </li>
    <li>
      I-RENT SRL (qui di seguito denominata "Locatore") consegna al locatario
      (qui di seguito denominato "Cliente") il veicolo o i veicoli specificati
      in seno al verbale di consegna in buono stato di manutenzione. Il veicolo,
      se non è diversamente indicato nel verbale di consegna, ha il serbatoio
      pieno di carburante ed è dotato del triangolo per la sosta, degli attrezzi
      usuali, della gomma di scorta, del giubbino catarifrangente, della carta
      verde assicurativa e di tutti i documenti necessari per la circolazione,
      incluso il certificato e il contrassegno di assicurazione, nonché degli
      altri accessori indicati a pagina uno. Il Cliente, prendendo in consegna
      il veicolo, mediante la sottoscrizione del contratto di noleggio recante
      le condizioni particolari, la specifica accettazione delle presenti
      Condizioni Generali e del verbale di consegna, dichiara di aver verificato
      che il medesimo è in buono stato di manutenzione ed idoneo all'uso
      pattuito e, altresì, è dotato di tutti gli oggetti sopra-indicati.
    </li>
    <li>
      II Locatore garantisce che per la responsabilità civile del Cliente e di
      qualsiasi altra persona autorizzata a condurre il veicolo è in vigore una
      polizza assicurativa che prevede un massimale unico di € 20.000.000 per i
      danni alle persone, €5.000.000 alle cose. A richiesta del Cliente sono
      disponibili le polizze assicurative indicate nel contratto di noleggio a
      copertura di ulteriori danni così come specificato nelle singole polizze
      di assicurazione, le cui clausole e condizioni il Cliente dichiara di
      conoscere, accettare e di impegnarsi a rispettare.
    </li>
    <li>
      Il Cliente si obbliga a:
      <ol
        type="a"
        class="irt-contract-conditions">
        <li>
          condurre il veicolo ed a custodirlo congiuntamente agli accessori
          forniti, in modo diligente e nel rispetto di tutte le norme di legge;
        </li>
        <li></li>
        <li>
          curare la manutenzione ordinaria del veicolo e quindi a provvedere
          all'ingrassaggio, al controllo ed all'eventuale livellamento dei
          lubrificanti e/o dell'olio dei freni;
        </li>
        <li>
          provvedere all'oblazione di qualsiasi contravvenzione comminata per la
          circolazione del veicolo noleggiato, al pagamento dei pedaggi
          autostradali e agli oneri di qualsiasi natura derivanti da parcheggi,
          durante il tempo della locazione ed a rimborsare il Locatore di ogni
          eventuale spesa sostenuta;
        </li>
        <li>
          manlevare il Locatore da qualsivoglia pretesa avanzata da terzi per
          danni da questi ultimi subiti;
        </li>
      </ol>
      Il Cliente riconosce di non essere titolare d'alcun diritto reale sul
      veicolo noleggiato e sugli accessori forniti e, quindi, di non poterne
      disporre in alcun modo.
    </li>
    <li>
      Il Cliente s'impegna:
      <ol
        type="a"
        class="irt-contract-conditions">
        <li>
          a non condurre o usare il veicolo in uno Stato per il quale non sia
          valida la carta verde assicurativa;
        </li>
        <li>
          a non condurre o usare il veicolo per fini commerciali quali trasporto
          di persone o cose;
        </li>
        <li>
          a non concedere in godimento a terzi non autorizzati dal locatore il
          veicolo;
        </li>
        <li>
          a non condurre o usare il veicolo per spingere o trainare rimorchi o
          altri veicoli;
        </li>
        <li>
          a non condurre o usare il veicolo sotto l'influenza di droghe,
          narcotici, alcolici od intossicanti ovvero di altre sostanze idonee a
          menomare la capacità d'intendere e volere;
        </li>
        <li>
          a non condurre o usare il veicolo per partecipare a gare, prove del
          percorso di esse e/o competizioni di qualsiasi genere, sportive e no;
        </li>
        <li>
          a non condurre o usare il veicolo per ogni scopo contrario alla legge;
        </li>
        <li>
          a non condurre o usare il veicolo su percorsi accidentati o "fuori
          strada";
        </li>
        <li>
          a non condurre o usare il veicolo in contrasto con leggi e regolamenti
          dello Stato;
        </li>
        <li>
          a non consentire la guida e/o l’utilizzo del veicolo noleggiato a
          persone diverse da quella indicata a pagina uno;
        </li>
        <li>
          a non consentire in alcun caso che il veicolo sia condotto da persona
          di età diversa da quella prevista dalle Condizioni Particolari di cui
          al contratto di noleggio o priva di patente di guida valida nello
          Stato nel quale il veicolo è condotto;
        </li>
      </ol>
      Per quanto non previsto valgono le norme del nuovo "codice della strada".
    </li>

    <li>
      ll Cliente si obbliga a risarcire il Locatore per qualsiasi danno o furto del veicolo nessuna ipotesi esclusa, anche se parziale e ne risponde fino all’ammontare del valore del veicolo per tutte spese sorte al Locatore, nessuna esclusa, oltre ad una tassa amministrativa (Damage Processing Fee) pari ad euro 250,00 oltre iva. Per costo sostenuto s’intende, tra l’altro, spese per la riparazione di danni, valore corrente della vettura in caso di furto, perdita di valore, trasporto, perizie, pratiche amministrative per danni, mancata possibilità di noleggio del veicolo, franchigia RC e perdita bonus. Il Cliente risponde in proprio fino a quando il Locatore non abbia ufficialmente confermato il suo esonero dal risarcimento danni.
      Il Locatore ha diritto di far riparare il veicolo presso una carrozzeria o un meccanico di sua fiducia e di ottenere il costo della riparazione (manodopera e pezzi di ricambio) dietro esibizione del preventivo e/o della fattura.
      Per la determinazione del valore di mercato del veicolo, si conviene che si adotterà il valore risultante dai listini pubblicati da “Quattroruote” o “Autoscout24”.
      Il cliente non potrà sollevare eccezioni in ordine al pagamento dei sopra indicati danni e costi sostenuti dal Locatore, se non dopo aver adempiuto integralmente alle proprie obbligazioni.
    </li>

    <li>
      La responsabilità per danno o furto veicolo viene ridotto o azzerato nel caso il Cliente sottoscriva i pacchetti assicurativi kasko o full kasko (C.D.W. e T.P.) indicati nel modello delle Condizioni Particolari che il Cliente dichiara di aver ricevuto e le cui condizioni dichiara di accettare. Il Cliente, prendendo in consegna il veicolo, accetta gli importi delle franchigie dovuti per danni e/o furto e incendio.
    </li>

    <li>Il Cliente risponde illimitatamente, anche in caso di stipulazione di responsabilità limitata ed in caso di colpevolezza del conducente aggiuntivo, di danni causati per violazione contrattuale e danni causati intenzionalmente o per negligenza grave sul/all’interno del veicolo.</li>

    <li>Il locatario non potrà sollevare eccezioni in ordine all’esecuzione della prestazione contrattuale da parte della società locatrice, se non avrà adempiuto integralmente alle proprie obbligazioni, concernenti in particolare il pagamento della prestazione, le spese ed eventuale risarcimento danni arrecati.</li>

    <li>
      Qualora si verifichi un sinistro, il Cliente si obbliga a:
      <ol
        type="a"
        class="irt-contract-conditions">
        <li>
          informare immediatamente per telefono il Locatore, trasmettendogli
          entro le successive 24 ore il CID compilato dettagliatamente in ogni
          sua parte o, in mancanza, denuncia dettagliata dei fatti a mezzo email
          all’indirizzo info@irent-autonoleggio.com;
        </li>
        <li>informare la più vicina autorità di polizia;</li>
        <li>non rilasciare dichiarazioni di responsabilità;</li>
        <li>
          prendere nota di tutti i nomi e indirizzi delle parti e degli
          eventuali testimoni incluse le generalità delle Compagnie
          Assicuratrici dei mezzi coinvolti (compagnia, numero di polizza,
          agenzia);
        </li>
        <li>fornire al Locatore qualsiasi altra notizia utile;</li>
        <li>
          comunicare nel medesimo termine di cui alla lettera a) le informazioni
          sub lettera d) a mezzo email all’indirizzo info@irent-autonoleggio.com;
        </li>
        <li>
          seguire le istruzioni che il Locatore fornirà relativamente alla
          custodia e/o alla riparazione del veicolo;
        </li>
      </ol>
    </li>
    <li>
      In caso di furto, o tentato furto del veicolo noleggiato, il Cliente si
      obbliga a denunciare immediatamente il fatto alle Autorità competenti
      (Polizia o Carabinieri) ed a consegnare al Locatore originale della
      denuncia, le chiavi del veicolo e dell'antifurto, se il mezzo ne è
      provvisto. Il corrispettivo del noleggio è dovuto fino alla data di
      consegna della copia della denuncia e delle chiavi, oltre all'importo
      dell'eventuale franchigia.
    </li>
    <li>
      Il Cliente si obbliga a riconsegnare il veicolo nel luogo ed entro la data
      indicata nelle condizioni particolari o in seno al verbale di consegna o
      comunque appena il Locatore gliene faccia richiesta, con i medesimi
      accessori e nel medesimo stato nel quale l'ha ricevuto, salvo la normale
      usura. Nel caso di smarrimento delle chiavi, il cliente si obbliga a
      corrispondere il costo richiesto dalla casa madre per la sostituzione più
      eventuali penali;
    </li>
    <li>
      Il Cliente si obbliga a corrispondere al Locatore la tariffa prevista e tutti gli extra indicati nelle Condizioni Particolari di cui ne abbia fatto richiesta alla sottoscrizione del contratto di noleggio, nonché l'importo necessario per ripristinare l'originale livello del serbatoio di carburante oltre alle spese per il relativo servizio, l’importo corrispondente a tutte le contravvenzioni ricevute, ai pedaggi autostradali non corrisposti e agli oneri di qualsiasi natura derivanti da parcheggi, nonché le somme relative a qualsiasi altro servizio di cui il Cliente e/o il conducente abbiano usufruito.
      II Cliente che utilizza per il pagamento dei corrispettivi una carta di credito finanziaria, accetta che tutti i menzionati addebiti siano effettuati sul suo conto. Il Cliente che all'atto della sottoscrizione del contratto di noleggio presenti un voucher prepagato resta comunque obbligato solidalmente con il Locatore qualora l'importo non venga pagato dal soggetto emittente del voucher stesso. Il Cliente è comunque obbligato al pagamento degli extra derivanti dal noleggio.
    </li>

    <li>
      PIANO TARIFFARIO - REVISIONE DELLE TARIFFE: I-RENT si riserva di apportare variazioni alle tariffe concordate durante l’esecuzione dello stesso, allorché si verifichino eventi che determinino una variazione dei costi de gestione quali ad esempio: provvedimenti amministrativi o governativi, incremento delle tasse di proprietà e immatricolazione degli autoveicoli, variazioni condizioni delle case automobilistiche, premi assicurativi, oneri fiscali, aumento tasso ufficiale di sconto, etc.. Le tariffe concordate saranno inoltre soggette ad adeguamento in caso di variazione in aumento dell’indice dei prezzi ISTAT al consumo delle famiglie di operai ed impiegati l’entità dell’adeguamento sarà pari all’importo corrispondente al 100% della variazione. La variazione tariffaria sarà comunicata al Cliente con preavviso e si intenderà in vigore sin dal momento indicato nella stessa comunicazione, fatto salvo il diritto del Cliente di recedere dal presente accordo.
    </li>

    <li>
      Chi stipula la locazione in nome o per conto di un terzo risponde in
      solido con questi dell'esecuzione delle obbligazioni del contratto di
      noleggio assumendo la qualifica di garante a prima richiesta senza
      possibilità di sollevare eccezione alcuna e senza alcun obbligo a carico
      del Locatorio di preventiva escussione del terzo, rinunciando inoltre al
      termine di decadenza di cui all’art. 1957 c.c.. Nel caso in cui il terzo
      neghi la valida costituzione del rapporto, in ogni caso chi stipula la
      locazione in nome o per conto del predetto terzo, risponde in proprio
      dell'esecuzione delle obbligazioni del contratto di noleggio Il Cliente,
      risponde, in ogni caso, delle azioni ed omissioni di chiunque conduca il
      veicolo.
    </li>
    
    <li>
      Il Cliente assume piena responsabilità per tutti i pedaggi stradali, multe o altre conseguenze dovute a contravvenzioni stradali (inclusi addebiti per accesso a zone di traffico limitato), a divieti di sosta, a mancato pagamento dei parcheggi ove previsto, o a violazione di ogni altra disposizione di legge o regolamento durante tempo della locazione ed in ogni caso durante l’eventuale detenzione senza titolo.
      Qualora venga irrogata una multa e/o contravvenzione per la violazione
      degli obblighi previsti dal codice della strada scaturenti dall’utilizzo
      del veicolo durante il tempo della locazione ed in ogni caso durante
      l’eventuale detenzione senza titolo, il Cliente si obbliga a:
      <ol
        type="a"
        class="irt-contract-conditions">
        <li>
          informare immediatamente per telefono il Locatore, indicandogli nelle
          successive 24 ore luogo, ora e tipologia della violazione commessa a
          mezzo email all’indirizzo info@irent-autonoleggio.com;
        </li>
        <li>
          informare immediatamente per telefono il Locatore, trasmettendogli
          nelle successive 24 ore il verbale di contestazione ricevuto a mezzo
          email all’indirizzo info@irent-autonoleggio.com;
        </li>
        <li>
          comunicare alle autorità competenti le generalità del conducente alla
          guida del veicolo al tempo della violazione di legge contestata entro
          il termine assegnato;
        </li>
        <li>
          pagare la multa e/o contravvenzione e trasmettere entro 30 giorni
          prova del pagamento integrale a mezzo email all’indirizzo
          info@irent-autonoleggio.com;
        </li>
      </ol>
      Per ogni multa a carico del Cliente verranno addebitati €24,59 oltre IVA come costi di gestione pratica.
    </li>

    <li>
      Il Locatore non è responsabile nei confronti del Cliente, del conducente,
      dei terzi trasportati o dei membri delle rispettive famiglie per i danni
      di qualsiasi natura, incluso il pregiudizio economico dai medesimi subìto
      nelle persone o nei beni, in conseguenza di guasti o difetto di
      funzionamento del veicolo o incidenti stradali. In ogni caso il Locatore
      non può essere considerato responsabile di qualunque tipo di danno
      verificatosi a seguito di furti, tumulti, incendi, terremoti, guerre o
      altre cause di forza maggiore. Gli oggetti da chiunque lasciati nel
      veicolo si intendono abbandonati ed il Locatore non è tenuto a custodirli
      ed a restituirli.
    </li>

    <li>
      Il Cliente si obbliga a corrispondere il corrispettivo pattuito anche nel
      caso di riconsegna anticipata del veicolo, non prevedendosi alcun rimborso
      per gli eventuali giorni di inutilizzo.
    </li>

    <li>
      Il Cliente si obbliga a corrispondere il corrispettivo pattuito entro i termini essenziali indicati nelle condizioni particolari o in seno al verbale di consegna.
      Il pagamento del corrispettivo convenuto o di quant'altro dovuto anche per oneri accessori in forza del contratto di noleggio non può venire sospeso o ritardato da pretese o eccezioni del Cliente, quale ne sia il titolo, valendo in favore del Locatore la clausola limitativa della proponibilità di eccezioni di cui all’art. 1462 del c.c.
      Il mancato puntuale pagamento, per qualsiasi causa, anche di una sola rata del corrispettivo convenuto entro il termine essenziale indicato nelle condizioni particolari o nel verbale di consegna, per patto espresso verrà considerato come grave inadempimento che giustifica la risoluzione del contratto di noleggio.
      Il cliente autorizza il Locatore ad addebitare sulla carta di credito di cui sono stati forniti i dati completi, tutte le somme dovute fino a concorrenza dei crediti maturati in forza del contratto di noleggio, a qualsiasi titolo, nessuno escluso, ivi compresi danni, corrispettivi, franchigie etc.
    </li>

    <li>
      Il Cliente si obbliga a riconsegnare il veicolo nel luogo ed entro la data
      indicata nelle condizioni particolari o nel verbale di consegna o comunque
      a far tempo dalla risoluzione di diritto per grave inadempimento secondo
      la clausola risolutiva espressa sub art 19, con i medesimi accessori e nel
      medesimo stato nel quale l'ha ricevuto, salvo la normale usura.
    </li>

    <li>
      Nel caso di cessazione del presente contratto per scadenza naturale, per
      risoluzione e/o nel caso di scioglimento per ogni ulteriore causa,
      l’eventuale mancato rispetto dell’obbligo di consegna del veicolo nel
      luogo indicato nelle condizioni particolari o nel verbale di consegna con
      i medesimi accessori e nel medesimo stato nel quale l'ha ricevuto, salvo
      la normale usura, comporterà, a carico del cliente, e senza alcun onere di
      messa in mora: l’obbligo di corrispondere al locatario, a titolo di penale
      per il mancato rispetto dell’obbligo di consegna, una somma pari al
      corrispettivo pattuito per ogni giorno, o alla frazione equivalente nel
      caso di locazione per più giorni, aumentata del 50%, per ogni giorno di
      ritardo fino all’effettiva riconsegna del bene in via spontanea o coattiva
      e salvo il maggior danno. Il pagamento della penale dovrà essere
      corrisposto a semplice richiesta, senza che il Cliente possa sollevare
      eccezioni alcuna, valendo in favore del Locatore la clausola limitativa
      della proponibilità di eccezioni di cui all’art. 1462 del c.c.
    </li>

    <li>
      Il Locatore non autorizza il Cliente a condurre la vettura all'estero al
      regime di temporanea esportazione salvo specifica autorizzazione scritta.
    </li>

    <li>
      Per qualsiasi controversia fra le parti relativa all’interpretazione,
      validità, esecuzione e/ risoluzione del contratto di noleggio è competente
      in via esclusiva, con esclusione di qualsiasi altro foro concorrente, il
      Tribunale di Siracusa o il Giudice di Pace di Siracusa.
    </li>

    <li>
      Per ogni aspetto non regolato dalle presenti condizioni generali varranno
      le pattuizioni inserite nelle condizioni particolari e/o nel verbale di
      consegna. In caso di contrasto prevarranno le pattuizioni espresse
      inserite nelle condizioni particolari e/o nel verbale di consegna.
    </li>

    <li>
      Il Cliente acconsente all'inserimento nel sistema computerizzato ed al
      trattamento dei Suoi dati personali per l'esecuzione del presente
      contratto di noleggio nonché alla diffusione dei predetti dati tra le
      società di noleggio ai soli fini della protezione dei legittimi interessi
      del Locatore, inclusi la commercializzazione dei servizi di noleggio ed il
      controllo del credito, nonché l’adempimento di obblighi di legge. In caso
      di inadempimento contrattuale, i dati personali potranno essere diffusi o
      trasmessi a terzi anche al fine di permettere il recupero del credito.
    </li>

    <li>
      Il conducente prende atto dell’esigenza di cui agli art. 94 comma 4-bis
      c.d.s. e 247-bis, comma 2, let. B del d.p.r. n. 495/1992, che sia
      comunicato alla motorizzazione civile il nominativo di ciascun
      utilizzatore che abbia detenuto il veicolo noleggiato per più di 30
      giorni; tale obbligo grava sul conducente ed egli è tenuto ad adempirvi
      personalmente, con oneri a proprio carico.
    </li>
  </ol>
</div>
<span style="font-size: 8px">
  Ai sensi degli arti. 1341 e 1342 c.c., le parti dichiarano di approvare in via
  espressa e specifica le clausole di cui ai nn. da 1) a 26)
</span>
