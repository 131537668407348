<app-toolbar
  [currentItem]="currentItem"
  [logo]="logo"
  [forceBlackToolbar]="true"></app-toolbar>

<div id="irt-pricing-root-layout">
  <h1 class="irt-no-margin irt-centered-text">
    Prezzi Fiat Panda Benzina/Hybrid
  </h1>
  <h2 class="irt-no-margin irt-primary-medium-color irt-centered-text">
    Pre-autorizzazione di 25€ per ogni prenotazione. Il prezzo include il
    chilometraggio illimitato.
  </h2>
  <div
    id="irt-pricing-list-layout"
    class="irt-full-width irt-double-margin-top"
    *ngIf="packs && packs.length > 0">
    <div
      id="irt-pricing-item-layout"
      class="irt-border-radius"
      *ngFor="let pack of packs">
      <h3 class="irt-no-margin">{{ pack }}</h3>
    </div>
  </div>
</div>

<app-home-social></app-home-social>

<app-footer></app-footer>
